import { useEffect, useRef } from "react";
import useLocationState from "../../hooks/useLocationState";
import ViewPort from "../Layout/ViewPort";
import useUser from "../../hooks/useUser";
import { BlueSky } from "ss-lib";

export const SubmissionInfo = () => {
  const { setTitle } = useUser();
  const { state } = useLocationState();
  const h = useRef();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://platform.twitter.com/widgets.js";
    script.async = true;
    script.id = "twitScript";
    document.querySelector("body")?.appendChild(script);

    return () => {
      document.querySelector("body").removeChild(script);
    };
  });

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    document.title = "ScholarSift - Submission Info"
    setTitle("Submission Info");
  }, [state.viewport]);

  const header = (
    <h1>
      Submission<br></br>
      <span>Info</span>
    </h1>
  );
  const text = (
    <main id="slide-right-nav">
      <h1 id="slide-header-sm">FAQs</h1>
      <ul className="faqs">
        <li>
          <div className="faqs-h2">
            ScholarSift is committed to making legal scholarship more accessible.
          </div>
          <div className="faqs-p">
            We believe that charging fees for submitting manuscripts to journals creates an unnecessary barrier to entry in legal scholarship. 
            We built a free manuscript submission portal to help fulfill our commitment to accessibility and allow those with limited institutional support to submit their work. 
          </div>
        </li>
        <li>
          <div className="faqs-h2">
            Journals
          </div>
          <div className="faqs-p">
            ScholarSift provides a free submission option for everyone. 
            If you would like to use ScholarSift to manage your submissions, please reach out to us at <a href="mailto:inquiries@scholarsift.com">inquiries@scholarsift.com</a>. 
            We will be happy to get your journal started on our platform and provide free training and support. 
          </div>
        </li>
        <li>
          <div className="faqs-h2">
            Authors
          </div>
          <div className="faqs-p">
            Submissions will always be free on ScholarSift. 
            All you need to do is create a free account. 
            Once you have an account, you can use our free online analysis tools and submit your manuscripts for free. 
            We provide a <a href="/calendar">Submission Calendar</a> where you can see when upcoming submission windows will open. 
            We can even notify you when a window is about to open. 
          </div>
        </li>
        <li>
          <div className="faqs-p">
            We want our tools to be as useful as possible for everyone involved in legal scholarship. 
            If you have an idea or request for a new feature, please let us know! You can email us at <a href="mailto:inquiries@scholarsift.com">inquiries@scholarsift.com</a> or reach us on:
          </div>
        </li>
      </ul>
    </main>
  );

  return (
    <ViewPort
      style={{
        height: h.current,
        overflow: "auto",
      }}
    >
      <div className="slide-container" style={{ height: h.current }}>
        <section className="slide-contents">
          <div
            className="slide"
            id="slide-left"
            style={{ width: "30%", height: "100%", position: "fixed" }}
          >
            <header className="slide-body">{header}</header>
          </div>
          <div id="slide-right" style={{ height: "90%", overflow: "auto" }}>
            <div
              className="faq-slide"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {text}
              </div>
              <div style={{textAlign: "center"}}>
                  <a 
                    style={{marignTop: 5}}
                    href="https://twitter.com/ScholarSift?ref_src=twsrc%5Etfw" 
                    className="twitter-follow-button"
                    data-size="large"
                    data-show-count="false"
                  >
                    @ScholarSift
                  </a>
              </div>
              <div style={{textAlign:"center"}}>
                <BlueSky handle="ScholarSift" />
              </div>
            </div>
          </div>
        </section>
      </div>
    </ViewPort>
  );
};
