import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import { Debug } from 'ss-lib';
import axios from 'axios';

export const ListPapers = async (user, saveUser, data, setItems, setFetching, count, delayLength) => {
    if(Debug()) {
        console.log("LIST PAPERS"); 
        console.log(data)
    }
    axios
        .post("/api/papers/", data, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then((results) => {
            if(Debug()) {
                console.log("PAPER LISTINGS:")
                console.log(results.data)
            }
            if (results.data.items.length > 0) {
                if(Debug())
                    console.log("Setting Items...")
                setItems(results.data);
            }
            setFetching(false);
        })
        .catch((err) => {
            if (Debug())
                console.log(err);
            if (err.status === 400) {
                return;
            }
            APIErrorHandler(
                err,
                user,
                saveUser,
                () => {
                    ListPapers(user, saveUser, data, setItems, setFetching, count ? count + 1 : 1, delayLength ? delayLength : 1000);
                }
            );
        })
}

export const CheckFile = (user, saveUser, f, setNewItem, setError, count, delayLength) => {
    let data = new FormData();
    data.append("file", f);
    data.append("fn", f.name);

    axios
        .post("/api/addpaper/", data, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then((results) => {
            if (Debug())
                console.log(results);
            setNewItem(results.data);
        })
        .catch((err) => {
            if (Debug())
                console.log(err);
            if (err.status === 400) {
                return;
            }
            APIErrorHandler(
                err,
                user,
                saveUser,
                () => {
                    CheckFile(user, saveUser, f, setNewItem, setError, count ? count + 1 : 1, delayLength ? delayLength : 30);
                }
            );
        })
}

export const EditPaper = (user, saveUser, item, callBack, setError, count, delayLength) => {

    axios
        .post("/api/editpaper/", item, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then((results) => {
            if (Debug())
                console.log(results);
            if (callBack)
                callBack();
        })
        .catch((err) => {
            if (Debug())
                console.log(err);
            if (err.status === 400) {
                return;
            }
            APIErrorHandler(
                err,
                user,
                saveUser,
                () => {
                    EditPaper(user, saveUser, item, callBack, setError, count ? count + 1 : 1, delayLength ? delayLength : 30);
                }
            );
        })
}

export const SearchPapers = async (user, saveUser, text, setItems, setError, count, delayLength) => {
    axios
        .post("/api/cite", { text: text }, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then((results) => {
            if (Debug())
                console.log(results)
            setItems(results.data.closest.Citation);
        })
        .catch((err) => {
            if (Debug())
                console.log(err);
            if (err.status === 400) {
                return;
            }
            APIErrorHandler(
                err,
                user,
                saveUser,
                (v) => v ? setError(v) : SearchPapers(user, saveUser, text, setItems, setError, count ? count + 1 : 1, delayLength ? delayLength : 30)
            );
        })
}

export const AddItem = async (user, saveUser, data, callBack, setError, count, delayLength) => {
    axios
        .post("/api/addclaim", data, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then((results) => {
            if (Debug())
                console.log(results)
            if (callBack)
                callBack();
        })
        .catch((err) => {
            if (Debug())
                console.log(err);
            if (err.status === 400) {
                return;
            }
            APIErrorHandler(
                err,
                user,
                saveUser,
                (v) => v ? setError(v) : AddItem(user, saveUser, data, callBack, setError, count ? count + 1 : 1, delayLength ? delayLength : 30)
            );
        })
}

export const RemoveItem = (user, saveUser, data, callBack, setError, count, delayLength) => {
    axios
        .post("/api/removeclaim", data, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then((results) => {
            if (Debug())
                console.log(results)
            if (callBack)
                callBack();
        })
        .catch((err) => {
            if (Debug())
                console.log(err);
            if (err.status === 400) {
                return;
            }
            APIErrorHandler(
                err,
                user,
                saveUser,
                (v) => v ? setError(v) : RemoveItem(user, saveUser, data, callBack, setError, count ? count + 1 : 1, delayLength ? delayLength : 30)
            );
        })
}

export const UpdateItem = (user, saveUser, data, callBack, setError, count, delayLength) => {
    const { file, item }  = data;

    let fd = new FormData();
    fd.append("file", file);
    fd.append("fn", file.name);
    fd.append("n", item.n.toString());

    //Upload Form Data
    axios
        .post("/api/addpaper", fd, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then((results) => {
            if(results.data.info) {
                setError("This version already exists.");
                return ;
            }
            EditPaper(user, saveUser, {...item, "pdf id":results.data["pdf id"], md5: results.data.md5, replacement: true, file: results.data.file}, callBack, setError, count, delayLength);
        })
        .catch((err) => {
            if (Debug())
                console.log(err);
            if (err.status === 400) {
                return;
            }
            APIErrorHandler(
                err,
                user,
                saveUser,
                (v) => v ? setError(v) : UpdateItem(user, saveUser, data, callBack, setError, count ? count + 1 : 1, delayLength ? delayLength : 30)
            );
        })
}

export const UpdatePaperStats = (user, saveUser, data, callBack, setError, count, delayLength) => {
    axios
        .post("/api/upkv", data, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then((results) => {
            if (callBack)
                callBack();
        })
        .catch((err) => {
            if (Debug())
                console.log(err);
            if (err.status === 400) {
                return;
            }
            APIErrorHandler(
                err,
                user,
                saveUser,
                (v) => v ? setError(v) : RemoveItem(user, saveUser, data, callBack, setError, count ? count + 1 : 1, delayLength ? delayLength : 30)
            );
        })
}