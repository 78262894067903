import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useToastController, Toast, ToastBody, ToastTitle } from '@fluentui/react-components';
import useUser from '../../hooks/useUser';
import useLocationState from '../../hooks/useLocationState';
import ViewPort from "../Layout/ViewPort"
import { LoadingAnimation, ManageJournal, ssToast } from 'ss-lib';
import { LoadData, UpdateData } from './actions';

export const JournalManagerComponent = props => {
    const { user, saveUser, setTitle } = useUser();
    const [group, setGroup] = useState(user.current && user.current.scholarsift && user.current.scholarsift.groupPrefs ? user.current.scholarsift.groupPrefs[0] : undefined)
    const location = useLocation();
    const [domain, setDomain] = useState("");
    const [doneLoading, setDoneLoading] = useState(false);
    const [groups, setGroups] = useState();
    const [templates, setTemplates] = useState();
    const [users, setUsers] = useState();
    const { dispatchToast } = useToastController(ssToast);

    useEffect(() => {
        setTitle("Journal Manager");
        document.title = "Journal Manager";
    })

    useEffect(() => {
        setGroup(user.current && user.current.scholarsift && user.current.scholarsift.groupPrefs ? user.current.scholarsift.groupPrefs[0] : undefined);
    }, [user.current])

    useEffect(() => {
        //Load data
        LoadData(user, saveUser, setGroups, setTemplates, setUsers, setDomain, setDoneLoading);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const Success = (msg, title) => {
        dispatchToast(<Toast appearance='inverted'><ToastTitle>{title ? title : "Success"}</ToastTitle><ToastBody>{msg}</ToastBody></Toast>, {intent: "information"})
    }

    const SaveGroup = v => {
        var key = "/submissions/manager";
        delete v.lid;
        UpdateData(user, saveUser, {
                lid: group.lid,
                type:"group", 
                data: {journal: group.journal, [key]: v}
            }, 
            vv => {
                vv[key].id = vv.id;
                vv[key].lid = group.lid;
                Success("Saved Group"); 
                if(v.id === undefined || v.id < 0)
                    setGroups([...groups, vv[key]]);
                else {
                    var g = groups.find(vv => v.id === vv.id);
                    Object.keys(v).forEach(k => g[k] = v[k]);
                    setGroups([...groups]);
                }

                var usr = {...user.current};
                var g = usr.scholarsift.groupPrefs.find(gp => gp.id === v.id);
                if(g) {
                    Object.keys(v).forEach(k => g[key][k] = v[k]);
                    saveUser(usr);
                }
            }
        );
    }

    const SaveInfo = v => {
        UpdateData(user, saveUser, {
                lid: group.lid, 
                type:"info", 
                data: v
            }, 
            vv => {
                Success("Saved Info");
                setGroups(groups.map(g => {g.journal = vv.name; return g}))
            }
        );
    }

    const SaveTemplate = v => {
        UpdateData(user, saveUser, {
                lid: group.lid, 
                type:"template", 
                data: v
            }, 
            vv => { 
                Success("Saved Templates");
                if(v.id === undefined || v.id < 0)
                    setTemplates([...templates, v]);
                else {
                    var t = templates.find(vv => v.id === vv.id);
                    Object.keys(v).forEach(k => t[k] = v[k]);
                    setTemplates([...templates]);
                }
            }
        );
    }
    
    const SaveUser = v => {
        if(users.find(u => u.email === v.email) === undefined)
            Success("Creating User", "information");
        UpdateData(user, saveUser, {
                lid: group.lid, 
                type:"user", 
                data: v
            }, 
            vv => { 
                if(users.find(u => u.email === v.email) === undefined)
                {
                    Success("Created User"); 
                    setUsers([...users, vv]);
                }
                else {
                    Success("Saved User"); 
                    var u = users.find(vv => v.email === vv.email);
                    Object.keys(v).forEach(k => u[k] = v[k]);
                    setUsers([...users]);
                }    
            }
        );
    }
    
    return  !doneLoading ?
            <LoadingAnimation msg="Loading"/> :
            <ManageJournal 
                height={props.height ? props.height * .75 : undefined}
                domain={domain}
                name={group ? group.journal : undefined}
                groups={groups}
                SaveGroup={SaveGroup}
                SaveInfo={SaveInfo}
                templates={templates}
                SaveTemplate={SaveTemplate}
                users={users}
                SaveUser={SaveUser}
            />
}

export const JournalManager = (props) => {
    const { state } = useLocationState();
    const h = useRef();
    
    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f + state.viewport.o);
        if (v !== h.current) h.current = v;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport]);

    return  <ViewPort>
                <div style={{ minHeight: Math.max(0, (isNaN(h.current) ? 0 : h.current - 10))}}>
                    <JournalManagerComponent {...props} height={h.current} />
                </div> 
            </ViewPort>
}