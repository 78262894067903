import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoadingAnimation } from 'ss-lib/dist/cjs';
import useUser from '../../../hooks/useUser';
import { NotifyProfile } from '../actions';
import ViewPort from '../../Layout/ViewPort';
import useLocationState from "../../../hooks/useLocationState";

export const Notify = props => {
    const [saving, setSaving] = useState(false);
    const { user, saveUser } = useUser();
    const { state } = useLocationState();
    const navigate = useNavigate();

    useEffect(() => {
        if(!saving) {
            setSaving(true);
            NotifyProfile(user, saveUser, () => {
                navigate(-2);
            });
        }
    })
    return  <ViewPort>
                <div
                    style={{
                    minHeight:
                        state.viewport.h -
                        (state.viewport.m + state.viewport.f),
                    }}
                >
                        <LoadingAnimation msg="Saving Profile" />
                </div>
            </ViewPort>
}