import { useEffect, useRef } from "react";
import useLocationState from "../../hooks/useLocationState";
import Slide from "../Layout/Splash/Slide";
import ViewPort from "../Layout/ViewPort";
import { NavLink } from "react-router-dom";
import useUser from "../../hooks/useUser";

const About = () => {
  const { state } = useLocationState();
  const {setTitle} = useUser();
  const h = useRef();

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.viewport]);

  useEffect(() => {
    document.title = "ScholarSift - About us";
    setTitle("About us")
  });

  const header = (
    <h1 style={{ fontWeight: 600 }}>
      About
      <span> us</span>
    </h1>
  );
  const text = (
    <main id="slide-right-nav">
      <h1 id="slide-header-sm">
        About
        <span> us</span>
      </h1>
      <p>ScholarSift is the first analytical platform to deploy machine learning to legal scholarship.</p>
      <p>
        ScholarSift's co-founders previously developed an analytics technology for transactional drafting, which is now a flagship feature for one of the largest legal research platforms.
        Now they are working to create a law review system that works better for authors, editors, and readers of legal scholarship.
      </p>
      <p>
         We are committed to helping authors throughout the drafting process, helping authors share their preprints, 
         and letting authors <a href="/manuscripts">submit</a> their work to journals for <b>free</b>.
      </p>
      <p>
        We offer journals <b>free</b> analysis and submission tools to help them find the right articles for their issues.
      </p>
      <p>
        If you have any questions or would like to know more, please reach out to us at <a href="mailto:inquiries@scholarsift.com">inquiries@scholarsift.com</a>.
      </p>
      <div style={{justifySelf:"center", marginTop: 100}}>
        <NavLink to="/faqs">FAQs</NavLink>
      </div>
    </main>
  );

  return (
    <ViewPort>
      <Slide header={header} text={text} />
    </ViewPort>
  );
};

export default About;
