import { useEffect, useMemo, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import MenuPanel from "./MenuPanel";

import Nav from "./Nav";
import useUser from "../../hooks/useUser";
import { resetUser } from "../../actions/Signin";
import useMeasure from "react-use-measure";
import useLocationState from "../../hooks/useLocationState";
import { ItemSEO } from "./ItemSEO";
import { useWindowDimensions, Tutorial } from "ss-lib";
import { ContactIcon } from "@fluentui/react-icons-mdl2";
import RoundedButton from "../Layout/Buttons/RoundedButton";
import "./Nav.scss";

const UrlMap = {
  "Analyze": "https://ss-how-to.s3.us-east-2.amazonaws.com/Analyze_04-23-2024.mp4",
  "Papers": "https://www.youtube.com/embed/oKd7c3irDQg",
  "Submit": "https://www.youtube.com/embed/ZYZhy7dxcKE?si=qyBPVT_atIss8JOv"
}

const JMM = {
  "Journal Manager": "https://www.youtube.com/embed/W6vPLkgMo10?si=Q_26duw4qGBO26gK",
}

const SMM = {
  "Submission Manager": "https://www.youtube.com/embed/Rzjq0IGTCUo?si=-5-4fJLxPx0P3JzY",
}

const NavButtonGroup = (props) => {
  const location = useLocation();
  const [ref, { height }] = useMeasure();
  const navigate = useNavigate();
  const { currentUser, currentTitle, saveUser, allowRoute, isJournalManager } = useUser();
  const [title, setTitle] = useState(currentTitle);
  const { dispatch } = useLocationState();
  const dim = useWindowDimensions();
  const h = useRef();

  const ss = currentUser;
  const isAuth =
    ss === null || ss === undefined ? false : ss.scholarsift.profile > -1;
  const isAdmin = isAuth && ss.scholarsift.isAdmin;
  const isGroupAdmin = isAuth && (isAdmin || ss.scholarsift.roles === 1);
  const isLawReview = useMemo(() => allowRoute("lr"), [currentUser]);

  const tutorials = useMemo(() => <>
    {UrlMap[title] && <div style={{ height: "fit-content", marginTop: "10px", alignSelf: "center" }}><Tutorial style={{ marginRight: "5px" }} title={title + " Tutorial"} url={UrlMap[title]} primary="Close" /></div>}
    {isLawReview && SMM[title] && <div style={{ height: "fit-content", marginTop: "10px", alignSelf: "center" }}><Tutorial style={{ marginRight: "5px" }} title={title + " Tutorial"} url={SMM[title]} primary="Close" /></div>}
    {isJournalManager && JMM[title] && <div style={{ height: "fit-content", marginTop: "10px", alignSelf: "center" }}><Tutorial style={{ marginRight: "5px" }} title={title + " Tutorial"} url={JMM[title]} primary="Close" /></div>}
    </>, [title])
  useEffect(() => {
    if (height !== h.current) {
      h.current = height;
      dispatch({
        type: "path-key-update",
        path: "viewport",
        key: "m",
        payload: Math.floor(height),
      });
    }

    return () => {
      dispatch({
        type: "path-key-update",
        path: "viewport",
        key: "m",
        payload: 0,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height]);

  useEffect(() => {
    setTitle(currentTitle);
  }, [currentTitle]);

  const signOut = () => {
    dispatch({ type: "reset" });
    resetUser(saveUser);
    navigate("/signin", location);
  };

  const handleNav = (e) => {
    e.preventDefault();
    return navigate("/signin", location.pathname.indexOf("/papers/") > -1 ? { state: { location: location.pathname } } : undefined);
  };

  return <>
    {location.pathname.indexOf("/papers/") === -1 ? <ItemSEO /> : undefined}

    <nav className="navbar-wrapper" ref={ref} id={props.darkMode ? "nw-dark" : undefined}>
      <div className="navbar-brand">
        {dim.width > 867 && !title ? (
          <>
            <img
              className="navbar-logo"
              src={
                props.darkMode
                  ? "/LOGO/ScholarSift-Logo_ReverseColor.png"
                  : "/LOGO/ScholarSift-Logo_FullColor.png"
              }
              alt="Brand Logo"
              onClick={() => {
                navigate("/");
              }}
            />

          </>
        ) : (
          <div
            style={{
              width: "fit-content",
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              alignContent: "center",
            }}
          >
            <img
              src={
                props.darkMode
                  ? "/LOGO/KnockoutSmallLogo.png"
                  : "/LOGO/ScholarSift-Logo_Favicon.png"
              }
              className="navbar-short-logo"
              alt="Brand Logo"
              onClick={() => {
                navigate("/");
              }}
            />
            <div
              className={
                props.darkMode ? "nt-dark navbar-title" : "navbar-title"
              }
            >
              {title}
            </div>
            {tutorials}
            {
              window.location.pathname.indexOf("scholarsift") === -1 && dim.width > 650 &&
              <div
                className={
                  props.darkMode ? "nt-dark navbar-title" : "navbar-title"
                }
              >
                <span style={{ color: "white", marginLeft: "10px", fontSize: "12pt" }}>Sept 19, 2024<br></br>21:00:00 UTC</span>
              </div>
            }
          </div>
        )}
      </div>
      <div className="nav-row">
        <div className="nav-row">
          <Nav
            darkMode={props.darkMode}
            url="/tutorials"
            name="Tutorials"
            id="tutorials"
          />
          <Nav darkMode={props.darkMode} url="/faqs" name="FAQs" id="faqs" />
          <Nav
            darkMode={props.darkMode}
            url="/analyze"
            name="Analyze"
            id="anlyze"
          />
          <Nav
            darkMode={props.darkMode}
            url="/manuscripts"
            name="Submissions"
            id="manuscripts"
          />
          <Nav
            darkMode={props.darkMode}
            url="/faculty"
            name="Faculty Bios"
            id="faculty"
          />
          {
            /*
            <Nav
              darkMode={props.darkMode}
              url="/subscribe"
              name="Subscribe"
              id="subscribe"
            />
            */
          }
        </div>
        {/* Test below w/o dim.width */}
        {dim.width > 1250 && isAuth ? (
          <div>
            <MenuPanel
              isAuth={isAuth}
              isAdmin={isAdmin}
              isLawReview={isLawReview}
              isGroupAdmin={isGroupAdmin}
              signOut={signOut}
              user={currentUser}
              darkMode={props.darkMode}
            />
          </div>
        ) : (
          <div>
            <RoundedButton
              title="Sign In"
              id="nav-auth-btn"
              icon={<ContactIcon className="nav-icon" />}
              onClick={handleNav}
            />
          </div>
        )}
        {dim.width < 1250 && (
          <MenuPanel
            isAuth={isAuth}
            isAdmin={isAdmin}
            isLawReview={isLawReview}
            isGroupAdmin={isGroupAdmin}
            signOut={signOut}
            user={currentUser}
            darkMode={props.darkMode}
          />
        )}
      </div>
    </nav>    </>

};

export default NavButtonGroup;
