import { useEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import useUser from '../../hooks/useUser';
import useLocationState from '../../hooks/useLocationState';
import ViewPort from "../Layout/ViewPort"
import { LoadingAnimation, Messaging } from 'ss-lib';
import { ListMessages, SendMessage, TouchThread } from './actions';

export const MessagesComponent = props => {
    const location = useLocation();
    const [doneLoading, setDoneLoading] = useState(location && location.state && location.state.header);
    const [messages, setMessages] = useState([]);
    const [thread, setThread] = useState();
    const [part, setPart] = useState();
    const [newMessage, setNewMessage] = useState(location.state && !location.state.part ? location.state : undefined)
    const { user, saveUser, setTitle, nMessages, setNMessages } = useUser();
   

    useEffect(() => {
        setTitle("Messaging");
        document.title = "Messaging";
    })

    useEffect(() => {
        //Fetch Messages
        if((!location.state || location.state.part)) {
            //Set Message info
            if(location.state && location.state.thread) {
                setThread(location.state.thread);
            }

            //List Messages
            ListMessages(user, saveUser, (v) => {setDoneLoading(true); if(location.state && location.state.part) touchThread(location.state.part, v); else setMessages(v)});
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [location.pathname])

    const addMessage = msg => {
        var m = messages ? [...messages] : [];
        var f = m.find(v => v.thread === msg.thread)
        if(f)
            f.parts.push(msg.parts[0]);
        else
            m.push(msg);
        setMessages(m);
        setPart(-2);
        setNewMessage(undefined);
    }

    const handleMessage = v => {
        //Handle Action
        if(!v.header) {
            var m = messages ? [...messages] : [];
            var f = m.find(mv => mv.thread === v.thread)
            if(f) {
                v.title = f.title;
                var h = JSON.parse(f.parts[0].header);
                v.header = {from: user.current.scholarsift.profile }
                v.header.to = v.header.from === h.from ? h.to : h.from;
            }
        }
        SendMessage(user, saveUser, v, addMessage)
    }

    const touchThread = (v, msgs) => {
        var p = user.current.scholarsift.profile;
        var m = msgs ? msgs : [...messages];
        var f = m.find(msg => msg.thread === v)
        TouchThread(user, saveUser, {profile: p, thread: v, end: f.parts.length - 1})
        if(f) {
            if(v.startsWith(p + "-"))
                f.lend = f.parts.length - 1;
            else
                f.pend = f.parts.length - 1;
            setMessages(m)
            setThread(v)
            setPart(f.parts.length - 1);
        }
        if(nMessages)
            setNMessages(nMessages - 1);
    }

    return  !doneLoading ?
            <LoadingAnimation msg="Loading"/> :
            <Messaging newMessage={newMessage} messages={messages} thread={thread} part={part} handleMessage={handleMessage} touchThread={touchThread} profile={user.current.scholarsift.profile} />
}

export const Messages = (props) => {
    const { state } = useLocationState();
    const h = useRef();
    
    useEffect(() => {
        let v = state.viewport.h - (state.viewport.m + state.viewport.f + state.viewport.o);
        if (v !== h.current) h.current = v;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [state.viewport]);

    return  <ViewPort>
                <div style={{ height: Math.max(0, (isNaN(h.current) ? 0 : h.current) - 20)}}>
                    <MessagesComponent {...props} />
                </div> 
            </ViewPort>
}