import { APIErrorHandler } from "../../../actions/APIErrorHandler";
import axios from 'axios';

export const FetchCalendarItems = (user, saveUser, callBack) => {
    let d = { id: user.current && user.current.scholarsift ? user.current.scholarsift.profile : undefined};
    axios
      .post("/api/calendar", d, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      })
      .then((res) => {
        if(callBack)
          callBack(res.data);
      })
      .catch((error) => {
        APIErrorHandler(error, user, saveUser, () => {
            FetchCalendarItems(user, saveUser, callBack);
        });
      });
  };