import { useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import useLocationState from "../../hooks/useLocationState";
import Slide from "../Layout/Splash/Slide";
import ViewPort from "../Layout/ViewPort";
import useUser from "../../hooks/useUser";

/*
const getSocials = () => {
  <a
          href="https://twitter.com/ScholarSift?ref_src=twsrc%5Etfw"
          className="twitter-follow-button"
          data-size="large"
          data-show-count="false"
        >
          Follow @ScholarSift
        </a>
}
*/

const FAQs = () => {
  const { setTitle } = useUser();
  const { state } = useLocationState();
  const h = useRef();

  useEffect(() => {
    let v = state.viewport.h - (state.viewport.m + state.viewport.f);
    if (v !== h.current) h.current = v;
    // eslint-disable-next-line react-hooks/exhaustive-deps
    document.title = "ScholarSift - FAQs"
    setTitle("FAQs");
  }, [state.viewport]);

  const header = (
    <h1>
      FAQ
      <span>s</span>
    </h1>
  );
  const text = (
    <main id="slide-right-nav">
      <h1 id="slide-header-sm">FAQs</h1>
      <ul className="faqs">
        <li>
          <div className="faqs-h2">
            What is ScholarSift, and how does it differ from other legal
            research tools?
          </div>
          <div className="faqs-p">
            ScholarSift is an advanced legal research software that stands out
            from other legal research tools by offering comprehensive analytics,
            source recommendations, and Bluebook citations. ScholarSift's unique
            ability to deliver tailored, up-to-date information sets it apart as
            an invaluable resource for legal research.
          </div>
        </li>
        <li>
          <div className="faqs-h2">
            Where does ScholarSift's data come from?
          </div>
          <div className="faqs-p">
            Most of our article data is downloaded from digital commons and
            bepress repositories. Some of our data is directly from law journals
            or law schools that have requested we index their data repositories.
            We would be happy to add your school's repository to our database.
            Please contact{" "}
            <a href="mailto:inquires@scholarsift.com">
              inquires@scholarsift.com
            </a>{" "}
            to learn more. Our case data comes from the{" "}
            <a href="https://case.law">case.law project</a>
          </div>
        </li>
        <li>
          <div className="faqs-h2">
            How does ScholarSift's gender breakdown work?
          </div>
          <div className="faqs-p">
            Gender counts are estimated using data from the Social Security
            "Baby Names" database.
          </div>
        </li>
        {
          /*
        <li>
          <div className="faqs-h2">
            What pricing options are available for ScholarSift, and are there
            any free trials?
          </div>
          <div className="faqs-p">
            ScholarSift strives to make legal research accessible. We offer our
            Basic plan for free, and law journals may use our submission portal
            at no cost. Please see our range of pricing options for our advanced
            subscriptions under the <a href="/subscribe">Subscribe Tab</a>. We
            also offer limited-time free trials on our social media channels!
            Follow us on Twitter, Instagram, and LinkedIn to look out for our
            exclusive deals.
          </div>
        </li>
        */
        }
        <li>
          <div className="faqs-h2">
            How can I integrate ScholarSift with other legal research tools or
            software I use?
          </div>
          <div className="faqs-p">
            You may run your draft article through ScholarSift at any stage in
            your drafting process for relevant source suggestions and Bluebook
            citations. In fact, you don't even need a draft to use ScholarSift;
            you can run a blog post, your class syllabus, or even just a list of
            citations to find relevant articles for your potential research
            projects. ScholarSift may be used in conjunction with any other
            legal research tool. Law journals may also use ScholarSift's free
            article submission portal in addition to their existing
            subscriptions with other article submission software.
          </div>
        </li>
        <li>
          <div className="faqs-h2">
            Does ScholarSift offer customer support or training resources for
            users?
          </div>
          <div className="faqs-p">
            Please visit the <a href="/tutorials">Tutorials Tab</a> for guided tutorials
            to learn how maximize all of ScholarSift's resources. You may also
            schedule a virtual <a href="/demo">Demo</a> with one of our team
            members for a personalized learning experience.
          </div>
        </li>
        <li>
          <div className="faqs-h2">
            Is my data and research conducted on ScholarSift kept confidential
            and secure?
          </div>
          <div className="faqs-p">
            Everything is deleted once analysis is complete.
          </div>
        </li>
        <li>
          <div className="faqs-h2">
            Can ScholarSift assist with international legal research and access
            to laws from different countries?
          </div>
          <div className="faqs-p">
            At the moment, ScholarSift's database indexes primarily legal
            information published in the United States, although we plan to
            expand our indexing reach. Nonetheless, the indexed publications
            cover a wide range of legal topics including international law and
            analyze laws from different countries.
          </div>
        </li>
        <li>
          <div className="faqs-h2">
            How frequently is ScholarSift updated with new legal information and
            cases?
          </div>
          <div className="faqs-p">
            ScholarSift is constantly indexing new legal information from
            digital commons and bepress repositories, as well as law journal and
            law school repositories. We would be happy to add your school's
            repository to our database. Please contact{" "}
            <a href="inquiries@scholarsift.com">inquiries@scholarsift.com</a> to
            learn more.
          </div>
        </li>
      </ul>
    </main>
  );

  return (
    <ViewPort
      style={{
        height: h.current,
        overflow: "auto",
      }}
    >
      <div className="slide-container" style={{ height: h.current }}>
        <section className="slide-contents">
          <div
            className="slide"
            id="slide-left"
            style={{ width: "30%", height: "100%", position: "fixed" }}
          >
            <header className="slide-body">{header}</header>
          </div>
          <div id="slide-right" style={{ height: "90%", overflow: "auto" }}>
            <div
              className="faq-slide"
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                }}
              >
                {text}
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                width: "70%",
                marginLeft:"auto",
                marginRight:"auto",
                marginBottom: 100,
                textAlign: "center",
              }}
            >
              <Link to="/demo">Schedule a demo</Link>
              <Link to="/about">About</Link>
            </div>
          </div>
        </section>
      </div>
    </ViewPort>
  );
};

export default FAQs;
