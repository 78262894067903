import { useEffect, useState } from "react";
import useUser from '../../hooks/useUser';
import useLocationState from "../../hooks/useLocationState";
import ViewPort from "../Layout/ViewPort";
import { SubmissionCalendar } from 'ss-lib';
import { FetchCalendarItems } from "./actions";
import { LoadingAnimation } from "ss-lib/dist/cjs";
import "./css/react-big-calendar.css";

export const SubmissionWindowCalendar = props => {
    const { user, saveUser } = useUser(); 
    const [items, setItems] = useState();
    const [fetching, setFetching] = useState();
    const { state } = useLocationState();
    const { setTitle } = useUser();
  
    useEffect(() => {
      setTitle("ScholarSift - Submission Window Calendar");
      document.title = "ScholarSift - Submission Window Calendar"

      if(!fetching && items === undefined) {
        setFetching(true);
        FetchCalendarItems(user, saveUser, v => { setItems(v); setFetching(false);})
      }
    })
  
    return  <ViewPort>
              <div
                style={{
                minHeight:
                  state.viewport.h -
                  (state.viewport.m + state.viewport.f),
                }}
              >
                {
                  items === undefined ? 
                  <LoadingAnimation msg="Loading Windows" />:
                  <SubmissionCalendar items={items} isSignedIn={user.current && user.current.scholarsift && user.current.scholarsift.profile}/>
                }
              </div>
            </ViewPort>
  }