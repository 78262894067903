import { Debug } from 'ss-lib';
import { APIErrorHandler } from '../../../actions/APIErrorHandler';
import axios from 'axios';

export const UpdateProfile = (
    user,
    saveUser,
    profile,
    callBack,
    setError,
  ) => {
    if (Debug()) {
      console.log("Updating Profile:");
      console.log(profile);
    }
    
    axios
      .post("/api/uprofile", profile, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      })
      .then((results) => {
        if (Debug())
          console.log(results);
        if (callBack) callBack(results.data);
      })
      .catch((error) => {
        APIErrorHandler(error, user, saveUser, () => {
          UpdateProfile(user, saveUser, profile, callBack, setError)
        });
      });
};

export const DeleteProfile = (user, saveUser, profile, callBack) => {
    if (!profile) return;
    let d = { profile: profile };
    axios
      .post("/api/delete", d, {
        headers: {
          Authorization: "Bearer " + user.current.token.id,
          Coda: user.current.scholarsift.coda,
        },
      })
      .then((results) => {
        callBack();
      })
      .catch((error) => {
        APIErrorHandler(error, user, saveUser, () => {
          DeleteProfile(user, saveUser, profile, callBack);
        });
      });
  };

  
export const NotifyProfile = (user, saveUser, callBack) => {
  if (!user.current) return;
  let d = { id: user.current.scholarsift.profile };
  axios
    .post("/api/notify", d, {
      headers: {
        Authorization: "Bearer " + user.current.token.id,
        Coda: user.current.scholarsift.coda,
      },
    })
    .then(() => {
      if(callBack)
        callBack();
    })
    .catch((error) => {
      APIErrorHandler(error, user, saveUser, () => {
        DeleteProfile(user, saveUser, callBack);
      });
    });
};