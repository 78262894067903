import { useEffect, useState } from 'react';
import { Toast, ToastBody, ToastTitle, useToastController } from "@fluentui/react-components";
import ViewPort from '../Layout/ViewPort';
import useLocationState from '../../hooks/useLocationState';
import useUser from '../../hooks/useUser';
import { Debug, LoadingAnimation, Submit, defaultProfile, ssToast  } from 'ss-lib';
import { ListJournals } from '../Submissions/actions';
import axios from 'axios';
import { APIErrorHandler } from '../../actions/APIErrorHandler';

const getProfile = (props, user) => {
    if(props.pdata)
      return props.pdata;
    if(user.current && user.current.scholarsift)
      return user.current.scholarsift.pinfo;
    return defaultProfile;
}

const GetSets = (user, saveUser, callBack) => {
    axios.get("/api/opensets", {
        headers: {
            Authorization: "Bearer " + user.current.token.id,
            Coda: user.current.scholarsift.coda,
        },
    })
    .then(res => {
        if(Debug())
            console.log(res.data)
        if(callBack)
            callBack(res.data.filter(v => !v.internal || v.domain.indexOf(user.current.scholarsift.email.substring(user.current.scholarsift.email.indexOf("@") + 1)) > -1));
    })
    .catch(err => {APIErrorHandler(err, user, saveUser, () => { GetSets(user, saveUser, callBack)})})
}

const SubmitComponent = props => {
    const { dispatchToast } = useToastController(ssToast);
    const { state } = useLocationState();
    const { user, saveUser, setTitle } = useUser();
    const [item, setItem] = useState({step: "type"});
    const [fetching, setFetching] = useState(false);
    const [journals, setJournals] = useState([]);
    const [jni, setJNI] = useState([]);
    const [profile] = useState(getProfile(props, user));
    const [hasAuths, setHasAuths] = useState(false);
    const [openSets, setOpenSets] = useState();
    const [fetchingOpenSets, setFetchingOpenSets] = useState(false);

    const debug = Debug();

    useEffect(() => {
        setTitle("Submit a Paper");
        if(window.location.pathname.indexOf("submit") > -1)
            document.title = "ScholarSift - Submit a Paper";
    })

    useEffect(() => {
        if(openSets === undefined && !fetchingOpenSets) {
            setFetchingOpenSets(true);
            GetSets(user, saveUser, (v) => {
                setOpenSets(v);
                setFetchingOpenSets(false); 
            })
        }
    }, [window.pathname])

    const GetJournals = type => {
        if(fetching)
            return ;

        setFetching(true);
        ListJournals(user, saveUser, type, (j) => {
            var lc = type.toLowerCase();
            var domain = user.current.scholarsift.email;
            domain = domain.substring(domain.indexOf("@") + 1);
            var os = openSets.filter(v => !v.internal || v.domain.split(",").indexOf(domain) > -1);
            var sets = j.sets.filter(jss => os.find(oss => jss.id === oss.subset));
            var js = j.journals.filter(jj => os.find(oss => oss.id === jj.id)).map(jj => ({[jj.name] : {[lc]: {...jj.settings[lc], sets: sets.filter(s => jj.id === s.lid)}}}))
            
            //Set
            setItem({...item, type: type, step:"manuscript"});
            setJournals(js);
            setJNI(j.journals.map(jj => ({name: jj.name, id:jj.id, subsets: sets.filter(s => jj.id === s.lid).map(s => ({name: s.name, id: s.id}))})))
            setFetching(false);
        });
    }
    
    const ProcessFile = (f, type) => {
        var data = new FormData();
        data.append("file", f);
        data.append("fn", f.name);
        axios.post("/api/chksub", data, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then(res => {
            if(debug)
                console.log(res)
            if(res.data.processing)
            {
                Clear();
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>{f.name} is already in the Submission Queue</ToastBody></Toast>, {intent: "info"});
                return ;
            }
            else if(res.data.Submitted) {
                Clear();
                dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>{f.name + " " + res.data.Submitted}</ToastBody></Toast>, {intent: "info"});
                return ;
            }
            var itm = {...res.data};
            itm.file = f;
            itm.step = "information";
            itm.type = type;
            if(debug)
                console.log(itm)
            setHasAuths(res.data.authors);
            setItem(itm);
        })
        .catch(err => {
            APIErrorHandler(err, user, saveUser, () => { ProcessFile(f) });
        })
    }

    const SelectFile = e => {
        dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Checking {e.target.files[0].name}</ToastBody></Toast>, {intent: "info"});
        ProcessFile(e.target.files[0], e.item.type);
    }
    
    const onDrop = e => {
        dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Checking {e.dataTransfer.files[0].name}</ToastBody></Toast>, {intent: "info"});
        ProcessFile(e.dataTransfer.files[0], e.item.type);
    }

    const Clear = () => {
        setItem({step:"type"})
        setFetching(false)
    }

    const CheckAuthors = auths => {
        axios.post("/api/chkauths", {authors:auths, id: item["submission id"]}, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then(res => {
            if(debug)
                console.log(res)
            setHasAuths(res.data.authors);
        })
        .catch(err => { 
            if(debug) {
                console.log("Error")
                console.log(err)
            }
            APIErrorHandler(err, user, saveUser, () => { CheckAuthors(auths) });
        })
    }

    const DoSubmit = (data) => {

        dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>Submitting {data.file.name}</ToastBody></Toast>, {intent: "info"});

        //Update This
        //Prep Form Data
        //Set Metadata
        var md = {...data};

        var d = new FormData();
        if(md.cover) {
            d.append("cover", md.cover);
            md.cover = md.cover.name;
        }

        //Set File
        md.file = md.file.name;

        //Process Schools & Separate Info
        md.schools = md.schools.map((k, i) => {
            if(md[k]) {
                if(md[k].cover) {
                    d.append("cover", md[k].cover);
                    md[k].cover = md[k].cover.name;
                }
            }
            var j = jni.find(v => v.name === k);
            var sd = {
                id: j.id,
                name: k,
                subset: j.subsets.find(s => s.name === md[k].set).id,
            }
            return sd;
        })

        //Process Authors
        const { authors } = md;
        for(var i = 0; i < authors.length; i++) {
            if(authors[i].cv && authors[i].cv instanceof File) {
                d.append("cv-" + i, authors[i].cv);
                authors[i].cv = authors[i].cv.name;
            }
        }

        //Add Metadata
        d.append("metadata", JSON.stringify(md));

        //Send & Process
        axios.post("/api/submit", d, {
            headers: {
                Authorization: "Bearer " + user.current.token.id,
                Coda: user.current.scholarsift.coda,
            },
        })
        .then(() => {
            Clear();

            //Toast
            dispatchToast(<Toast appearance='inverted'><ToastTitle>Notification</ToastTitle><ToastBody>You have successfully submitted {data.title}</ToastBody></Toast>, {intent: "success"}) 
            
        })
        .catch(err => { 
            APIErrorHandler(err, user, saveUser, () => { DoSubmit(data) });
        })
    }

    const missingName = profile === undefined || profile.name === undefined || profile.name.length === 0;
    const missingInst = profile === undefined || profile.institutions === undefined || profile.institutions.length === 0;
    const missingCat = profile === undefined || profile.categories === undefined || profile.categories.length === 0;
    const checkComplete = !missingName && !missingInst && !missingCat;

    if(!checkComplete)
        return  <ViewPort>
                    <div style={{ width:"80vw", minWidth:"400px", minHeight: state.viewport.h - (state.viewport.m + state.viewport.f)}} >
                        <h3 style={{paddingTop:200, textAlign:"center"}}>In order to submit a paper, you must complete the required portions of your <a href="/profile">profile</a>.</h3>
                    </div>
                </ViewPort>
    
    return  props.unwatched === undefined ? 
            <ViewPort>
                <div style={{ width:"80vw", minWidth:"400px", margin: "auto", minHeight: state.viewport.h - (state.viewport.m + state.viewport.f)}} >
                {
                    openSets === undefined ? 
                    <LoadingAnimation msg="Loading Submission Windows" /> :
                    openSets.length === 0 ? 
                    <div style={{display:"flex", flexDirection:"column"}}>
                        <h3 style={{alignContent:"center", width: "25vw", height: "15vh", margin: "auto"}}>There are no open submission windows at the moment. Please check back later.</h3>
                        <h3 style={{alignContent:"center", width: "25vw", height: "25vh", margin: "auto"}}><a href="/profile/notify">Click here</a> to be notified when submisison windows open.</h3>
                    </div> :
                    <Submit 
                        item={item}
                        journals={journals}
                        openSets={openSets}
                        profile={user.current ? user.current.scholarsift.public : undefined}
                        Clear={Clear}
                        DoSubmit={DoSubmit}
                        hasAuths={hasAuths}
                        CheckAuthors={CheckAuthors}
                        SelectFile={SelectFile}
                        onDrop={onDrop}
                        getJournals={GetJournals}
                    />
                }
                </div>
            </ViewPort> :
            openSets === undefined ? 
            <LoadingAnimation msg="Loading Submission Windows" /> :
            openSets.length === 0 ? 
            <h3 style={{alignContent:"center", width: "25vw", height: "25vh", margin: "auto"}}>There are no open submission windows at the moment.<br></br>Please check back later.</h3> :
            <Submit 
                item={item}
                journals={journals}
                openSets={openSets}
                profile={user.current ? user.current.scholarsift : undefined}
                Clear={Clear}
                DoSubmit={DoSubmit}
                hasAuths={hasAuths}
                CheckAuthors={CheckAuthors}
                SelectFile={SelectFile}
                onDrop={onDrop}
                getJournals={GetJournals}
            />
}

export default SubmitComponent;