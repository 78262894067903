import { Navigate, useLocation } from "react-router-dom";
import useUser from '../../hooks/useUser';

const LawReviewManagerRoute = ({children}) => {
  const { isJournalManager } = useUser();
  const location = useLocation();

  if(!isJournalManager)
    return <Navigate to='/signin' state={{location: location}} replace/>;
  return children;
}

export default LawReviewManagerRoute;