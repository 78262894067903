import React, { useEffect, useRef, useState } from "react";
import { MsalProvider } from '@azure/msal-react';
import { EventType, PublicClientApplication } from "@azure/msal-browser";
import creds from "../creds.json";
import { Debug, defaultPagePrefs, defaultGroupPrefs, defaultProfile, useProfile } from "ss-lib";
import axios from "axios";

const UserContext = React.createContext();

const defaultToken = {
  type: undefined,
  id: undefined,
  refresh: undefined,
};

const defaultScholarSiftInfo = {
  coda: undefined,
  email: undefined,
  start: undefined,
  isAdmin: undefined,
  name: undefined,
  paid: undefined,
  customerProfile: undefined,
  customerPaymentProfile: undefined,
  planId: undefined,
  profile: undefined,
  transId: undefined,
  subscriptionId: undefined,
  role: undefined,
  roles: undefined,
  source: undefined,
  zip: undefined,
  announcements: undefined,
  groupPrefs: [{...defaultGroupPrefs,
    name: "Default"
  }],
  pagePrefs: {
    "/portal/list": defaultPagePrefs
  }
};

const config = {
  auth: {
    clientId: creds.msftClientId,
    redirectUri: "https://" + window.location.host + "/signin",
    postLogoutRedirectUri: "https://" + window.location.host,
  },
};

export const defaultUser = {
  window: null,
  token: defaultToken,
  scholarsift: defaultScholarSiftInfo,
};

const RefreshUser = async (u) => {
  console.log("Building Request")
  let data = {
    grant_type: "refresh_token",
    refresh_token: u.token.refresh,
    headers: {
      Authorization:
      "Basic " + btoa(creds.clientId + ":" + creds.clientSecret),
      "Content-Type": "application/x-www-form-urlencoded",
    },
  };

  var user = defaultUser;
  await axios.post("/api/token_local", data)
                    .then((response) => {
                      console.log("Got Token")
                      console.log(response.data)
                      u.token.token = response.data.access_token;
                      u.token.refresh = response.data.refresh;
                      console.log("Calling Auth")
                      return axios.post(
                          "/api/auth",
                          { signon: u.token.type },
                          {
                            headers: {
                              Authorization: "Bearer " + u.token.token,
                            },
                          }
                        )
                    })
                    .then((res) => {
                        console.log("Got Auth Token");
                        console.log(res);
                      
                      if (res.data.error) {
                        if(Debug()) console.log(res.data.error);
                        return;
                      }
                
                      //Return User
                      user = {
                        token: {
                            type: u.type,
                            id: u.token.token,
                            refresh: u.token.refresh,
                          },
                          scholarsift: res.data,
                        };
                    })
                    .catch(err => {console.log(err); localStorage.removeItem("scholarsift.user");});
  return user;
}

export const extractUser = async () => {
  if(Debug())
    console.log(new Date())
  let v = localStorage.getItem("scholarsift.user");
  if(Debug())
    console.log(v)
  if (v === "undefined") {
    localStorage.removeItem("scholarsift.user");
    return defaultUser;
  } else if (v === null) return defaultUser;

  let now = Date.now() / 1000;
  try {
    let u = JSON.parse(localStorage.getItem("scholarsift.user"));
    if (!u.token || !u.token.id) return defaultUser;

    let strs = u.token.id.split(".");
    let j = JSON.parse(atob(strs[1]));
    if(Debug())
      console.log(j)
    console.log(j.exp + " " + now)
    if (
      u.token.type === "Microsoft" ||
      u.token.type === "Google" ||
      u.token.type === "Local"
    ) {
      if (j.exp > now)
        return { ...u };
      else if(u.token.type === "Local") {
        return RefreshUser(u);
      }
      localStorage.removeItem("scholarsift.user");
      return defaultUser;
    } else {
      //      console.log(u);
      //      console.log(j)
    }
  } catch (error) {
    console.log(error);
  }
  return defaultUser;
};

export const pca = new PublicClientApplication(config);
pca.initialize().then(()=> {
  if(Debug()) {
    console.log(pca.getActiveAccount())
    console.log(pca.getAllAccounts())
  }
});

//Axios Section
export const UserProvider = props => {
  const [nMessages, setNMessages] = useState(0);
  const [currentUser, setCurrentUser] = useState(props.user);
  const [isJournalManager, setIsJournalManager] = useState(currentUser && currentUser.scholarsift && currentUser.scholarsift.groupPrefs && currentUser.scholarsift.groupPrefs ? currentUser.scholarsift.groupPrefs.find(v => v["/submissions/manager"] && v["/submissions/manager"].canManage) !== undefined: false);
  const [currentTitle, setCurrentTitle] = useState(false);
  const [warningDisplayed, setWarningDisplayed] = useState(false);
  const { setProfile } = useProfile();
  const userRef = useRef(currentUser);
  const titleRef = useRef();
  const wsRef = useRef();

  const saveUser = (values) => {
    if (values) {
      localStorage.setItem("scholarsift.user", JSON.stringify(values));
      userRef.current = values;
      setCurrentUser(values);
    } else {
      localStorage.removeItem("scholarsift.user");
      userRef.current = defaultUser;
      setCurrentUser(defaultUser);
    }
  };

  useEffect(() => {
    setCurrentUser(props.user);
  }, [props.user])

  useEffect(() => {
    pca.addEventCallback((message) => {
      if(message.eventType === EventType.POPUP_OPENED)
        currentUser.window = message.payload.popupWindow;
    })

    if(currentUser.scholarsift) {
      if(currentUser.scholarsift.public)
        setProfile(currentUser.scholarsift.public);
      if(Debug()){
        console.log(currentUser)
        console.log(currentUser.scholarsift.groupPrefs);
        console.log(currentUser.scholarsift.groupPrefs.map(v => v["/submissions/manager"]))
        console.log(currentUser.scholarsift.groupPrefs ? currentUser.scholarsift.groupPrefs.find(v => v["/submissions/manager"].canManage) !== undefined: false)
      }
      setIsJournalManager(currentUser.scholarsift.groupPrefs ? currentUser.scholarsift.groupPrefs.find(v => v["/submissions/manager"].canManage) !== undefined: false);
    }
    else
    {
        setProfile(defaultProfile);
        setIsJournalManager(false)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentUser])

  const setTitle = (v) => {
    titleRef.current = v;
    setCurrentTitle(v);
  };

  const allowRoute = (type) => {
    let ss = currentUser.scholarsift;
    if (ss === undefined) return false;
    if (ss.profile === null || ss.profile === undefined) return false;
    if (type === "paid") return ss.paid || (ss.limits && ss.limits.length > 0) || ss.roles === 3;
    if (type === "gadmin") return ss.isAdmin || ss.roles === 1 || ss.roles === 2;
    if (type === "admin") return ss.isAdmin;
    if (type === "lr") {
      if(isJournalManager) {
        if(Debug())
          console.log("Law Review Admin");
        return true;
      }
      if (ss.groupPrefs && ss.groupPrefs.length > 0) {
        if(Debug())
          console.log("Law Review User")
        return true;
      }
      if(ss.isAdmin) {
        if(Debug())
          console.log("Law Review SS Admin")
        return true;
      }
      if(Debug())
        console.log("Not LR");
      return false;
    }
    return true;
  };

  //Add get messages
  return (
    <MsalProvider instance={pca}>
      <UserContext.Provider
        value={{
          user: userRef,
          currentUser,
          saveUser,
          title: titleRef,
          currentTitle,
          setTitle,
          ws: wsRef,
          allowRoute,
          warningDisplayed,
          setWarningDisplayed,
          nMessages,
          setNMessages,
          isJournalManager,
        }}
      >
        {props.children}
      </UserContext.Provider>
    </MsalProvider>
  );
};

export const UserConsumer = UserContext.Consumer;

export default UserContext;
